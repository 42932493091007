import { useContext, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { login } from './Component/Redux/slices/UserSlice';
import Login from './Component/Login/Login'
import Protectpages from './Component/ProtectPage'
import Home from './Pages/Home';
import 'react-toastify/dist/ReactToastify.css';
import '@mantine/core/styles.css';
import HomeTwoo from './Pages/HomeTwoo';
import Dashboard from './Component/Dashboard/Dashboard';
import ElMa8zon from './Component/ElM8zoon/ElMa8zon';
import StockThree from './Component/StockThree/StockThree';
import Employee from './Component/Empolyee/Employee';
import StokeTwo from './Component/StokeTwo/StokeTwo';
import Mortbat from './Component/Empolyee/mortbat'
import Money from './Component/Money/Money';
import Internet from './Component/Internet/Internet';
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const usertoken = localStorage.getItem('token');
    if (usertoken) {
      try {
        const parsedToken = JSON.parse(usertoken);
        const userName = localStorage.getItem('userName');
        dispatch(login(parsedToken, userName));
      } catch (error) {
        console.error('Error parsing token:', error);
      }
    }
    console.log(usertoken)
  }, [dispatch]);
  return (

    <div className="App">
      <BrowserRouter>

        <Routes>
        <Route element={<Protectpages />}>
        <Route path="/" element={<Dashboard />} />

        <Route path="/sale" element={<Home />} />
        <Route path="/saletwo" element={<HomeTwoo />} />
<Route path='/stock' element={<ElMa8zon/>}/>
<Route path='/stock3' element={<StockThree/>}/>
<Route path='/employees' element={<Employee/>}/>
<Route path='/stock2' element={<StokeTwo/>}/>
<Route path='/mortbat' element={<Mortbat/>}/>
<Route path='/money' element={<Money/>}/>
<Route path='/internet' element={<Internet/>}/>
          </Route>
          <Route path="/login" element={<Login />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
