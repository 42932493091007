import React, { useState ,useEffect} from "react";
import Sidebar from "../../Layout/Sidebar";
import styles from "../../Styles/dashboard.module.css";
import { Col, Row } from "react-bootstrap";

import ReactApexChart from "react-apexcharts";

import Form from 'react-bootstrap/Form';

import Chart from "./Chart";
import BranchTable from "../BranchTable/BranchTable";

import useSWR, { mutate, useSWRConfig } from "swr";
import { useSelector } from "react-redux";
import axios from "axios";

import {
    format
  } from "date-fns";
import Environment from "../../config";
const Home = () => {
  const [state, setState] = useState({
    series6: [{
        data: [12, 14, 2, 47, 42, 15, 47, 75, 65,],

    }],
    options6: {
        chart: {
            type: 'bar',
            width: 100,
            height: 35,
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 8,
                columnWidth: '50%'
            }
        }, title: {
            text: 'Revenue',
            offsetX: 0,
            style: {
                fontSize: '27px',
                color: '#949393',
                fontWeight: 'bold',
            }
        }, subtitle: {
            text: '$12,789.00',
            offsetX: 0,
            style: {
                fontSize: '40px',
                color: '#000',
                fontWeight: 'bold',
            }
        },
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9],
        xaxis: {
            crosshairs: {
                width: 1
            },
        },
        tooltip: {
            fixed: {
                enabled: false
            },
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function (seriesName) {
                        return ''
                    }
                }
            },
            marker: {
                show: false
            }
        },
        colors: ['#0178ff'],
        fill: {
            type: 'gradient',
            gradient: {
                type: 'vertical',
                colorStops: [
                    {
                        offset: 0,
                        color: '#0178ff'
                    },
                    {
                        offset: 25,
                        color: '#0178ff'
                    },
                    {
                        offset: 50,
                        color: '#71a8e6c9'
                    },
                    {
                        offset: 70,
                        color: '#3088ecc9'
                    },
                    {
                        offset: 100,
                        color: '#0178ffc9'
                    }
                ]
            }
        },
    }
})
const [dateFrom, setDateFrom] = useState("");
const [dateTo, setDateTo] = useState("");
const [selectedOption, setSelectedOption] = useState("currentDay");
const [data, setSubCategory] = useState([]);
const[load,setLoad]=useState(true)
const { token } = useSelector((state) => state.user);
const [debounceTimeout, setDebounceTimeout] = useState(null);
/* useEffect(() => {

 if(dateFrom&& dateTo){

 
    axios
      .get(`${Environment.baseURL}/api/BranchsCasherDetails?from=${dateFrom}&to=${dateTo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setSubCategory(response.data);
        console.log(response.data)
        setLoad(false)
      })
      .catch((err) => {
        console.log(err.response);
      });
      setLoad(true)
    }
}, [selectedOption,dateFrom , dateTo]); */
useEffect(() => {
  if (debounceTimeout) clearTimeout(debounceTimeout);

  const timeout = setTimeout(() => {
    if (dateFrom && dateTo) {
      axios
      .get(`${Environment.baseURL}/api/BranchsCasherDetails?from=${dateFrom}&to=${dateTo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setSubCategory(response.data);
        console.log(response.data)
        setLoad(false)
      })
      .catch((err) => {
        console.log(err.response);
      });
      setLoad(true)

    }
  }, 500); // Adjust the delay as needed

  setDebounceTimeout(timeout);

  return () => clearTimeout(timeout);
}, [dateFrom, dateTo]);
/* const fetcher = (args) => {
    const [url, token] = args;
    return axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setSubCategory(response.data);
        setLoad(false)
        return response.data;
      });
  };
  const { subCategory, error, isLoading } = useSWR(
    [
      `http://213.212.245.79:1973/api/BranchsCasherDetails?from=${dateFrom}&to=${dateTo}`,
      token,
    ],
    fetcher
  );
  if (error) console.log(error); */


  useEffect(() => {
    const today = new Date();
    let from, to;

    switch (selectedOption) {
      case "currentDay":
        from = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastMonth":
        from = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastQuarter":
        from = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastYear":
        from = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "custom":
        from = "";
        to = "";
        break;
      default:
        break;
    }

    setDateFrom(from ? format(from, "yyyy-MM-dd") : "");
    setDateTo(to ? format(to, "yyyy-MM-dd") : "");
  }, [selectedOption]); // Depend on selectedOption to update dates when it changes

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
  };

  const handleDateFromChange = (event) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event) => {
    setDateTo(event.target.value);
  };

  return (
    <>
      <section className={`${styles.home}`}>
      <Sidebar  active='dashboard'/>
        <div className={`${styles.homeContainer}`}>
        
       <h2 className={`${styles.h2} m-4`}>النقدية </h2>
          
                    <div className={`${styles.block }`}>
                        <Row className={`${styles.dash}`}>
                            <Col >
                              {/*   <h4>الأنشطة الأخيرة
                                </h4>
                                <div className={`${styles.activities}`}>
                                    <div className={`${styles.activities__body} ${styles.transit}`}>
                                        <p>Routes in transit</p>
                                        <h2>58</h2>
                                    </div>
                                    <div className={`${styles.activities__body}`}>
                                        <p>Routes delayed</p>
                                        <h2>3</h2>
                                    </div>
                                    <div className={`${styles.activities__body}`}>
                                        <p>Invoices paid</p>
                                        <h2>104</h2>
                                    </div>
                                    <div className={`${styles.activities__body}`}>
                                        <p>New booking</p>
                                        <h2>142</h2>
                                    </div>
                                </div> */}
                                {
                                    !load?
                                    <>
                                     <Row className="mt-5" dir="rtl">
                                     <Col>
                                     <label>اختر التاريخ</label>

                    <Form.Select
                      aria-label="Default select example"
                      value={selectedOption}
                      onChange={handleSelectChange}
                    >
           <option value="currentDay">اليوم الحالي</option>
                      <option value="lastMonth">اخر شهر</option>
                      <option value="lastQuarter">اخر ثلاثة اشهر</option>
                      <option value="lastYear">اخر سنة</option>
                      <option value="custom">اختر تاريخ محدد</option>
                    </Form.Select>
                  </Col>
                  <Col>
                    {selectedOption === "custom" && (
                      <div className={`${styles.col__date}`}>
                        <div>
                        <label>من تاريخ </label>
                          <Form.Control
                            type="date"
                            value={dateFrom}
                            onChange={handleDateFromChange}
                          />
                        </div>
                        <div>
                        <label>الي تاريخ</label>
                          <Form.Control
                            type="date"
                            value={dateTo}
                            onChange={handleDateToChange}
                          />
                        </div>
                      </div>
                    )}

                    {selectedOption !== "custom" && (
                      <div className={`${styles.col__date}`}>
                        <div>
                        <label>من تاريخ </label>
                          <Form.Control type="date" value={dateFrom} readOnly />
                        </div>
                        <div>
                        <label>الي تاريخ</label>
                          <Form.Control type="date" value={dateTo} readOnly />
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <div className="mt-2 mb-5">
                                    <BranchTable data={data}/>
                                    </div>
                                    {/* <div className={`${styles.dashchart}`}>
                                    <Chart />
                                    <div id="chart-6" className={`${styles.lastbar} `}>
                                        <ReactApexChart options={state.options6} series={state.series6} type="bar" height={250} />
                                    </div>
                                </div> */}
                                    </>
                                 
                                    :
                                    <div class="loader">
                                    <div class="justify-content-center jimu-primary-loading"></div>
                                  </div>
                                }
                              
                               
                            </Col>

                        </Row>
                    </div>

        </div>
      </section>
    </>
  );
};

export default Home;
