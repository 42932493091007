import React,{useState,useEffect} from 'react'
import styles from '../../Styles/elma8zon.module.css'
import Sidebar from '../../Layout/Sidebar'
const Mortbat = () => {
  return (
    <>
<section className={`${styles.home}`}>
       <Sidebar active="sho2on" />  
        <div className={`${styles.homeContainer}`}>
          <h2 className={`${styles.h2} m-4`}>  المرتبات </h2>
          <h1 className={`${styles.h1} `}>
            غير مفعلة في هذا الحساب
          </h1>
          </div>
          </section>
    </>
  )
}

export default Mortbat