import { useMemo,useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
const TableStokeTwo = ({data}) => {
    const columns = useMemo(
        () => [
            {
              accessorKey: 'branchName',
              header: '  اسم الفرع ',
     
              
            },
            {
                accessorKey: 'inventoryTurnover',
                header: ' معدل دوران الاصناف  ( ITR )',
                size: 250,
              },
            {
                accessorKey: 'dsi',
                header: '   عدد ايام بيع المخزون  ( DSI )',
                size: 250,
              },
              {
                accessorKey: 'sellThroughRate',
                header: 'معدل البيع ( STR )',
                size: 250,
              },
              {
                accessorKey: 'inventoryToSalesRatio',
                header: 'معدل تحويل المخزون لمبيعات ( ISR )',
                size: 300,
              },
          ],
          [],
      );
    
      
      const table = useMaterialReactTable({
        columns,
        data,
        columnResizeDirection: 'rtl',
        enableExpandAll: false,
        muiDetailPanelProps: () => ({
          sx: (theme) => ({
            backgroundColor:
              theme.palette.mode === 'dark'
                ? 'rgba(255,210,244,0.1)'
                : 'rgba(0,0,0,0.1)',
          }),
        }),
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        muiSearchTextFieldProps: {
          size: 'small',
          variant: 'outlined',
        },
        muiPaginationProps: {
          color: 'secondary',
          shape: 'rounded',
          variant: 'outlined',
          rowsPerPageOptions: [10, 20, 50], // Options for rows per page
  
         
        },
        pagination: {
          pageSize: 20, // Default page size
        },
        muiExpandButtonProps: ({ row, table }) => ({
          onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), 
          sx: {
            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s',
          },
        }),
        
        pagination: {
          pageSize: 20// Default page size
        },
      })
      return <MaterialReactTable table={table} />;
}

export default TableStokeTwo