import React, { useState,useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import styles from "../../Styles/account.module.css";
import TableDash from "./TableAcc";
import { ColorInput, Text } from "@mantine/core";
import SubTable from "./SubTable";
import Environment from "../../config";
import useSWR, { mutate, useSWRConfig } from "swr";
import { useSelector } from "react-redux";
import axios from "axios";
import ColorTable from "../ColorTable/ColorTable";
import SizeTable from "../SizeTable/SizeTable";
import Form from "react-bootstrap/Form";

import {
  format,
  subMonths,
  startOfMonth,
  endOfMonth,
  subQuarters,
  startOfQuarter,
  endOfQuarter,
  subYears,
  startOfYear,
  endOfYear,
} from "date-fns";
import { Col, Row } from "react-bootstrap";
const Homee = () => {
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [selectedOption, setSelectedOption] = useState("currentDay");
  const { token } = useSelector((state) => state.user);
  const [TableAll, setSubCategory] = useState([]);
  const [load, setLoad] = useState(true);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
/* 
  useEffect(() => {

    if(dateFrom&& dateTo){
   
       axios
         .get(`${Environment.baseURL}/api/ItemsSalesDetails?from=${dateFrom}&to=${dateTo}`, {
           headers: {
             Authorization: `Bearer ${token}`,
           },
         })
         .then((response) => {
           setSubCategory(response.data);
           console.log(response.data)
           setLoad(false)
         })
         .catch((err) => {
           console.log(err.response);
         });
         setLoad(true)
       }
   }, [selectedOption,dateFrom , dateTo]); */
   useEffect(() => {
    if (debounceTimeout) clearTimeout(debounceTimeout);
  
    const timeout = setTimeout(() => {
      if (dateFrom && dateTo) {
        axios
        .get(`${Environment.baseURL}/api/ItemsSalesDetails?from=${dateFrom}&to=${dateTo}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setSubCategory(response.data);
          console.log(response.data)
          setLoad(false)
        })
        .catch((err) => {
          console.log(err.response);
        });
        setLoad(true)
  
      }
    }, 500); // Adjust the delay as needed
  
    setDebounceTimeout(timeout);
  
    return () => clearTimeout(timeout);
  }, [dateFrom, dateTo]);
/*   const fetcher = (args) => {
    const [url, token] = args;
    return axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setSubCategory(response.data);
        setLoad(false);
        return response.data;
      });
  };
  const { subCategory, error, isLoading } = useSWR(
    [
      `http://213.212.245.79:1973/api/ItemsSalesDetails?from=${dateFrom}&to=${dateTo}`,
      token,
    ],
    fetcher
  );
  if (error) console.log(error);

  console.log(TableAll); */

  useEffect(() => {
    const today = new Date();
    let from, to;

    switch (selectedOption) {
      case "currentDay":
        from = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastMonth":
        from = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastQuarter":
        from = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "lastYear":
        from = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
        to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case "custom":
        from = "";
        to = "";
        break;
      default:
        break;
    }

    setDateFrom(from ? format(from, "yyyy-MM-dd") : "");
    setDateTo(to ? format(to, "yyyy-MM-dd") : "");
  }, [selectedOption]); 

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
  };

  const handleDateFromChange = (event) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event) => {
    setDateTo(event.target.value);
  };

  return (
    <>
      <section className={`${styles.home}`}>
        <Sidebar active="dashboard" />
        <div className={`${styles.homeContainer}`}>
          <h2 className={`${styles.h2} m-4`}>المبيعات </h2>

          <div className={`${styles.table__body}`}>
            {!load ? (
              <>
                <Row dir="rtl">
                <Col>
                    <label>اختر التاريخ</label>
                    <Form.Select
                      aria-label="Default select example"
                      value={selectedOption}
                      onChange={handleSelectChange}
                    >
           <option value="currentDay">اليوم الحالي</option>
                      <option value="lastMonth">اخر شهر</option>
                      <option value="lastQuarter">اخر ثلاثة اشهر</option>
                      <option value="lastYear">اخر سنة</option>
                      <option value="custom">اختر تاريخ محدد</option>
                    </Form.Select>
                  </Col>
                  <Col>
                    {selectedOption === "custom" && (
                      <div className={`${styles.col__date}`}>
                        <div>
                        <label>من تاريخ </label>
                          <Form.Control
                            type="date"
                            value={dateFrom}
                            onChange={handleDateFromChange}
                          />
                        </div>
                        <div>
                        <label>الي تاريخ</label>
                          <Form.Control
                            type="date"
                            value={dateTo}
                            onChange={handleDateToChange}
                          />
                        </div>
                      </div>
                    )}

                    {selectedOption !== "custom" && (
                      <div className={`${styles.col__date}`}>
                        <div>
                        <label>من تاريخ </label>
                          <Form.Control type="date" value={dateFrom} readOnly />
                        </div>
                        <div>
                        <label>الي تاريخ</label>
                          <Form.Control type="date" value={dateTo} readOnly />
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <div className="mt-5">
                  <TableDash data={TableAll?.dashItemsSales} />
                </div>
                <div className="mt-5">
                  <ColorTable data={TableAll?.colorsSales} />
                </div>
                <div className="mt-5">
                  <SizeTable data={TableAll?.sizesSales} />
                </div>
              </>
            ) : (
              <div class="loader">
                <div class="justify-content-center jimu-primary-loading"></div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Homee;
