import React,{useState,useEffect} from 'react'
import styles from '../../Styles/elma8zon.module.css'
import Sidebar from '../../Layout/Sidebar'
import { Col, Container, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import {
    format
  } from "date-fns";
const Employee = () => {
    const[load,setLoad]=useState(false)
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [selectedOption, setSelectedOption] = useState("currentDay");
    useEffect(() => {
      const today = new Date();
      let from, to;
  
      switch (selectedOption) {
        case "currentDay":
          from = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          break;
        case "lastMonth":
          from = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
          to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          break;
        case "lastQuarter":
          from = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
          to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          break;
        case "lastYear":
          from = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
          to = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          break;
        case "custom":
          from = "";
          to = "";
          break;
        default:
          break;
      }
  
      setDateFrom(from ? format(from, "yyyy-MM-dd") : "");
      setDateTo(to ? format(to, "yyyy-MM-dd") : "");
    }, [selectedOption]); // Depend on selectedOption to update dates when it changes
  
    const handleSelectChange = (event) => {
      const value = event.target.value;
      setSelectedOption(value);
    };
  
    const handleDateFromChange = (event) => {
      setDateFrom(event.target.value);
    };
  
    const handleDateToChange = (event) => {
      setDateTo(event.target.value);
    };

  return (
    <>
{/*       <section className={`${styles.home}`}>
        <Sidebar active="sho2on" />
        <div className={`${styles.homeContainer}`}>
          <h2 className={`${styles.h2} m-4`}> الموظفيين </h2>
          <Container>
          {
            !load?
            <div className={`${styles.block}`}>
            <Row className={`${styles.dash}`}>
              <Col>
              <Row className="mt-5 mb-4" dir="rtl">
                  <Col>
                    <label>اختر التاريخ</label>
                    <Form.Select
                      aria-label="Default select example"
                      value={selectedOption}
                      onChange={handleSelectChange}
                    >
           <option value="currentDay">اليوم الحالي</option>
                      <option value="lastMonth">اخر شهر</option>
                      <option value="lastQuarter">اخر ثلاثة اشهر</option>
                      <option value="lastYear">اخر سنة</option>
                      <option value="custom">اختر تاريخ محدد</option>
                    </Form.Select>
                  </Col>
                  <Col>
                    {selectedOption === "custom" && (
                      <div className={`${styles.col__date}`}>
                        <div>
                        <label>من تاريخ </label>
                          <Form.Control
                            type="date"
                            value={dateFrom}
                            onChange={handleDateFromChange}
                          />
                        </div>
                        <div>
                        <label>الي تاريخ</label>
                          <Form.Control
                            type="date"
                            value={dateTo}
                            onChange={handleDateToChange}
                          />
                        </div>
                      </div>
                    )}

                    {selectedOption !== "custom" && (
                      <div className={`${styles.col__date}`}>
                        <div>
                        <label>من تاريخ </label>
                          <Form.Control type="date" value={dateFrom} readOnly />
                        </div>
                        <div>
                        <label>الي تاريخ</label>
                          <Form.Control type="date" value={dateTo} readOnly />
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <div className={`${styles.activities}`} dir="rtl">
                  <div
                    className={`${styles.activities__body} ${styles.transit}`}
                  >
                    <p> عدد كال الموظفين العاملين </p>
                    <h2>45645635</h2>
                  </div>
                  <div className={`${styles.activities__body}`}>
                    <p> الموظفين الجدد  </p>
                    <h2>
                    78645633
                    </h2>
                  </div>
                  <div className={`${styles.activities__body}`}>
                    <p>  المستقيلين</p>
                    <h2>686545</h2>
                  </div>
                  <div className={`${styles.activities__body}`}>
                    <p>  معدل الاستقالات  </p>
                    <h2>
                     86786546
                    </h2>
                  </div>
                </div>
            
                
           
              </Col>
            </Row>
         
         
          </div>
            :
            <div class="loader">
                                    <div class="justify-content-center jimu-primary-loading"></div>
                                  </div>
          }
          </Container>
       
        </div>
      </section> */}
      <section className={`${styles.home}`}>
       <Sidebar active="sho2on" />  
        <div className={`${styles.homeContainer}`}>
          <h2 className={`${styles.h2} m-4`}>  الموظفيين </h2>
          <h1 className={`${styles.h1} `}>
            غير مفعلة في هذا الحساب
          </h1>
          </div>
          </section>
    </>
  )
}

export default Employee